import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Grid,
  theme,
  Avatar,
  Button,
  Heading,
  Container,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Devpost from "./Devpost";
import {
  FaGithub,
  FaLinkedinIn,
  FaEnvelope,
  FaFilePdf,
  FaHeart,
} from "react-icons/fa";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" minW={"100vw"}>
          <ColorModeSwitcher justifySelf="flex-end" mr={3} mt={3} />
          <VStack spacing={8}>
            <Avatar
              shadow={"2xl"}
              src={
                "https://firebasestorage.googleapis.com/v0/b/salamkhalilsite.appspot.com/o/myself.jpeg?alt=media&token=73a40819-1af3-439a-91af-51d1d49d61d8"
              }
              size={"2xl"}
            />
            <Container>
              <Heading>Salam Khalil</Heading>
              <Text>Web Developer</Text>
            </Container>
            <Flex
              px={3}
              flexDir={{ base: "column", sm: "row" }}
              wrap={"wrap"}
              w={"full"}
              justifyContent={"center"}
            >
              <SocialIcons
                text={"Github"}
                logo={FaGithub}
                url={"https://github.com/Salam81"}
              />
              <SocialIcons
                text={"LinkedIn"}
                logo={FaLinkedinIn}
                url={"https://www.linkedin.com/in/salamkhalil"}
                color={"#0077B5"}
              />
              <SocialIcons
                text={"Devpost"}
                logo={Devpost}
                url={"https://devpost.com/Salam81"}
              />
              <Flex w={"full"} justifyContent={"center"}>
                <SocialIcons
                  flexBasis={{ base: "100%", sm: "unset" }}
                  text={"Email"}
                  logo={FaEnvelope}
                  url={"mailto:salam.khalil98@gmail.com"}
                />
                <SocialIcons
                  flexBasis={{ base: "100%", sm: "unset" }}
                  text={"Resume"}
                  logo={FaFilePdf}
                  url={
                    "https://firebasestorage.googleapis.com/v0/b/salamkhalilsite.appspot.com/o/Dev-Resume.pdf?alt=media&token=b17d2081-2077-4896-80c1-79370f33be1e"
                  }
                />
              </Flex>
            </Flex>
            <Flex
              pb={3}
              fontSize={"xs"}
              align="center"
              w={"full"}
              justifyContent={"center"}
            >
              <Text mr={1}>Made with</Text>
              <Icon as={FaHeart} color={"red.400"} />
            </Flex>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

function SocialIcons({ text, logo, url, color, flexBasis }: any) {
  return (
    <Button
      flexBasis={flexBasis}
      mt={3}
      leftIcon={<Icon as={logo} color={color} />}
      size={"lg"}
      as={Link}
      href={url}
      isExternal
      mx={1}
    >
      {text}
    </Button>
  );
}
