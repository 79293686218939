import * as React from "react";
import { chakra, ImageProps, forwardRef } from "@chakra-ui/react";

export default forwardRef<ImageProps, "img">((props, ref) => {
  return (
    <chakra.img
      src={"./assets/devpost-icon.svg"}
      ref={ref}
      {...props}
      fontSize={"1.5rem"}
    />
  );
});
